<template>
  <div class="grow">
    <div class="sticky top-0 right-0 left-0 p-3 bg-black z-10">
      <nav class="container max-w-screen-md px-8 text-white">
        <h1 class="text-xl font-bold">
          <NuxtLink to="/admin">
            Admin
          </NuxtLink>
        </h1>
        <ul class="flex gap-4">
          <li>
            <NuxtLink to="/admin/customer">
              Customer
            </NuxtLink>
          </li>
          <li>
            <NuxtLink to="/admin/set-password">
              Set Admin Password
            </NuxtLink>
          </li>
          <li class="grow" />
          <li>
            <NuxtLink
              to="/admin/login"
              @click="reset"
            >
              Logout
            </NuxtLink>
          </li>
        </ul>
      </nav>
    </div>
    <div class="container max-w-screen-md p-8 bg-white flex flex-col gap-4">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAdminStore } from '@/stores'

const { reset } = useAdminStore()
</script>
